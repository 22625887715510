import { useQuery } from '@apollo/client';
import Footer from 'components/Footer';
import Layout from 'components/Layout';
import useLanguage from 'hooks/useLanguage';
import parse from 'html-react-parser';
import { QUERY } from 'interfaces/graph';
import React from 'react';

// TODO: better animation
export default function PrivacyPolicyPage() {
	const { language } = useLanguage();

	const { loading, error, data } = useQuery(QUERY.TERMS_OF_SERVICE, {
		fetchPolicy: 'cache-and-network',
		variables: {
			language
		},
		context: {
			headers: {
				'Accept-Language': language
			}
		}
	});
	
	const termsOfService = data?.termsOfService?.edges[0]?.node;
	
	return (
		<Layout loading={loading} error={error}>
			{termsOfService && (
				<div className='layout my-40'>
					<h1 className='sm:text-18 font-medium'>{termsOfService.name}</h1>
					{parse(termsOfService.content)}
				</div>
			)}
			<Footer />
		</Layout>
	);
}
